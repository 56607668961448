var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{on:{"click":_vm.onExport}},[_vm._v("Export All")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('Panel',{attrs:{"title":"Product Time Frame"}},_vm._l((_vm.timeFrames),function(timeFrame){return _c('v-checkbox',{key:timeFrame.label,staticClass:"facet-selection",attrs:{"dense":"","value":timeFrame,"ripple":false,"hide-details":true,"color":"gray","disabled":_vm.disableTimeFrame(timeFrame)},on:{"change":_vm.onTimeframeSelectedChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_c('v-icon',{attrs:{"color":_vm.getEosIconColor(timeFrame),"small":""}},[_vm._v(_vm._s(_vm.getEosIcon(timeFrame)))]),_vm._v(" "+_vm._s(timeFrame.label)+" ")],1)]},proxy:true}],null,true),model:{value:(_vm.mySelectedTimeFrames),callback:function ($$v) {_vm.mySelectedTimeFrames=$$v},expression:"mySelectedTimeFrames"}})}),1),_c('Panel',{staticClass:"mt-2",attrs:{"title":_vm.getCustomersTitle()}},[_c('v-treeview',{attrs:{"items":_vm.customersToShow,"item-key":"key","open":_vm.open,"value":_vm.selectedCustomers,"item-text":"label","selectable":"","selected-color":"gray","item-disabled":"disabled"},on:{"input":_vm.onCustomerSelectionChanged},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var item = ref.item;
var leaf = ref.leaf;
return [(!leaf)?_c('v-btn',{attrs:{"icon":"","x-small":""}},[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavorite(item)}}},[_vm._v(_vm._s(item.favorite ? '$starFill' : '$star'))])],1):_vm._e()]}},{key:"label",fn:function(ref){
var item = ref.item;
var leaf = ref.leaf;
return [_c('v-tooltip',{staticClass:"mt-2",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(leaf)?_c('span',[_vm._v(_vm._s(item.label))]):_c('a',{attrs:{"href":_vm.getCPEProfilesUrl(item),"target":"blank"}},[_vm._v(_vm._s(item.label))])])]}}],null,true)},[(leaf)?_c('span',[_vm._v(_vm._s(item.label))]):_c('span',[_vm._v("Click to go to CPE Profiles for "+_vm._s(item.label))])])]}}])})],1)],1),_c('v-col',[_c('Panel',{attrs:{"title":_vm.getProductsTitle()},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-tooltip',{staticClass:"mt-2",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"disabled":!_vm.canViewTimeline,"color":"primary"},on:{"click":_vm.viewTimeline}},[_vm._v("View Timeline")])],1)]}}])},[_c('span',[_vm._v("Clicking this button will take you to the "),_c('b',[_vm._v("Timeline")]),_vm._v(" tab. The products shown will be scoped to the customer.")])])],1),_c('v-col',[_c('v-tooltip',{staticClass:"mt-2",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"border-on-focus",attrs:{"disabled":_vm.canCreateReport,"color":"primary"},on:{"click":_vm.createReport}},[_vm._v("Build Report")])],1)]}}])},[_c('span',[_vm._v("Clicking this button will take you to the "),_c('b',[_vm._v("Report Builder")]),_vm._v(" tab. The products you've selected will be pre-populated in the report builder. Your customer's name will show on the "),_c('b',[_vm._v("Name your report")]),_vm._v(" line. Scroll down on the "),_c('b',[_vm._v("Report Builder")]),_vm._v(" page and click "),_c('b',[_vm._v("Generate and Save Report")]),_vm._v(" to create your report.")])])],1)],1)]},proxy:true}])},[_c('CustomerProductTable',{attrs:{"value":_vm.productsToShow,"licenses":_vm.licenses},on:{"product-selected":_vm.onProductSelected}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }